<template>
  <div>
    <page-title :heading="$t('settings.lang_navScaleSettings')" :subheading="$t('generic.lang_edit')" :icon=icon :show-previous-button="true" ></page-title>
    <div class="app-main__inner">
      <EditScaleSettingsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import EditScaleSettingsComponent from "../../../components/settings/moduleSettings/EditScaleSettingsComponent";

export default {
  components: {
    EditScaleSettingsComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>